import {Routes} from "@angular/router";
import {BookingModule} from "./booking/booking.module";
import {AuthGuard} from "../../../auth/auth,guard";

export const STATS_ROUTE: Routes = [
  {
    path: 'stats',
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'user',
      },
      {
        path: 'user',
        loadChildren: () => import('./user/user.module').then(module => module.UserModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'carer',
        loadChildren: () => import('./carer/carer.module').then(module => module.CarerModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'booking',
        loadChildren: () => import('./booking/booking.module').then(module => module.BookingModule),
        canActivate: [AuthGuard]
      }
    ]
  }
];
