import {Component, Input, OnInit} from '@angular/core';
import {DashboardService} from "../../../../services/dashboard/dashboard.service";

@Component({
  selector: 'app-parent-small-detail-overlay',
  templateUrl: './small-detail-overlay.component.html',
  styleUrls: ['./small-detail-overlay.component.scss']
})
export class ParentSmallDetailOverlayComponent implements OnInit {
  @Input() id?: any;
  parent?: any

  constructor(private dashboardService: DashboardService) { }

  ngOnInit(): void {
  }

  loadParent() {
    this.dashboardService
      .fetchParent(this.id, false)
      .subscribe(resp => {
        this.parent = resp.data
      })
  }
}
