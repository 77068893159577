import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {DatePipe} from "@angular/common";
import {Page} from "../../models/page.model";
import {catchError, finalize, map, Observable, of} from "rxjs";
import {BookingStatus} from "../../enums/booking-status.enum";
import {BaseResponse} from "../../models/base-response.model";
import { BASE_URL_SERVER } from 'src/environments/environment';
import {LoaderService} from "../loader.service";
import {MessageService} from "primeng/api";
import {P_TOAST_TIME} from "../../constants/basic.constants";
import {DateService} from "../date.service";
import {Router} from "@angular/router";

@Injectable({
  providedIn: 'root',
})
export class DashboardService {
  constructor(
    public http: HttpClient,
    private datePipe: DatePipe,
    private loadingService: LoaderService,
    private messageService: MessageService,
    private dateService: DateService,
    private router: Router,
  ) { }

  beforeSubscribe = () => this.loadingService.setLoading(true)

  afterSubscribe = () => this.loadingService.setLoading(false)

  successProcess(summary: string, detail: string) {
    return (data: any) => {
      this.messageService.add({life: P_TOAST_TIME, severity:'success', summary, detail});
      return data;
    }
  }

  errorCatching = (err: any) => {
    const errorResp = err.error ? err.error : err
    if (errorResp.resCode === 401) {
      this.router.navigateByUrl('/logout');
    }



    if (errorResp.fvError) {
      errorResp.fvError.forEach( (err: any) =>
        this.messageService.add({life: P_TOAST_TIME, severity: 'error', summary: errorResp.error.message ? errorResp.error.message : '', detail: err.message ? err.field + ': ' + err.message : err.field}
        )
      )
    } else if (errorResp.error) {
      this.messageService.add({life: P_TOAST_TIME,severity:'error', summary:errorResp.error.message, detail: errorResp.error.detailMessage});
    } else {
      this.messageService.add({life: P_TOAST_TIME,severity:'error', summary:'Error', detail: errorResp.message});
    }
    throw err;
  }

  searchPromoCode(startDate?: Date, expiryDate?: Date, searchByCode?: string, offset?: number) {
    const params: {[key: string]: any} = {}
    if (startDate) {
      params['startDate'] = this.startDateToString(startDate);
    }
    if (expiryDate) {
      params['expiryDate'] = this.startDateToString(expiryDate);
    }
    if (searchByCode) {
      params['code'] = searchByCode;
    }
    if (offset) {
      params['page'] = offset;
    }
    params['limit'] = 10;
    this.beforeSubscribe()
    return this.http.get<any>(`${BASE_URL_SERVER}/admin/reward/promocode`, {params})
      .pipe(
        catchError(this.errorCatching),
        finalize(this.afterSubscribe))
  }

  updatePromoCode(promoCode: any) {
    const body = JSON.stringify(promoCode);
    this.beforeSubscribe()
    return this.http.post<BaseResponse<any>>(`${BASE_URL_SERVER}/admin/reward/promocode/update`, body)
      .pipe(
        map(this.successProcess('Promo-code processing', `promo-code '${promoCode.code}' status: success`)),
        catchError(this.errorCatching),
        finalize(this.afterSubscribe)
      )
  };

  searchSitters(params: any) {
    this.beforeSubscribe()
    return this.http.get<any>(`${BASE_URL_SERVER}/admin/carer/list`, {params})
      .pipe(
        catchError(this.errorCatching),
        finalize(this.afterSubscribe))
  }

  fetchAvailableSitters = (bookingId: any) => {
    this.beforeSubscribe()
    return this.http.get<Page<any>>(`${BASE_URL_SERVER}/admin/booking/moreCarersForBooking/${bookingId}`, {})
      .pipe(
        catchError(this.errorCatching),
        finalize(this.afterSubscribe)
      )
  };

  searchParents(params: any){
    this.beforeSubscribe()
    return this.http.get<Page<any>>(`${BASE_URL_SERVER}/admin/parent/list`, {params})
      .pipe(
        catchError(this.errorCatching),
        finalize(this.afterSubscribe))
  }

  sendAdminSMS = (adminSmsNotificationRequest: any) => {
    const body = JSON.stringify(adminSmsNotificationRequest);
    this.beforeSubscribe()
    return this.http.post<BaseResponse<any>>(`${BASE_URL_SERVER}/admin/notification/sendSMSNotif`, body)
      .pipe(
        map(this.successProcess('sendAdminSMS', 'success')),
        catchError(this.errorCatching),
        finalize(this.afterSubscribe))
  };


  cancelBooking = (bookingRequest: any) => {
    var body = JSON.stringify(bookingRequest);
    this.beforeSubscribe()
    return this.http.post<BaseResponse<any>>(`${BASE_URL_SERVER}/admin/booking/cancelbooking`, body)
      .pipe(
        catchError(this.errorCatching),
        finalize(this.afterSubscribe))
  };

  editBooking = (editBookingVO: any) => {
    var body = JSON.stringify(editBookingVO);
    this.beforeSubscribe()
    return this.http.post<BaseResponse<any>>(`${BASE_URL_SERVER}/admin/booking/editBooking`, body)
      .pipe(
        catchError(this.errorCatching),
        finalize(this.afterSubscribe))
  };

  completeBooking = (bookingRequest: any) => {
    var body = JSON.stringify(bookingRequest);
    this.beforeSubscribe()
    return this.http.post<BaseResponse<any>>(`${BASE_URL_SERVER}/admin/booking/completebooking`, body)
      .pipe(
        catchError(this.errorCatching),
        finalize(this.afterSubscribe))
  };

  requestSitter(bookingId: any, sitterId: any, isOffer: any){
    this.beforeSubscribe()
    return this.http.post<BaseResponse<any>>(`${BASE_URL_SERVER}/admin/booking/requestcarer`, {},{params: {bookingId: bookingId, carerId: sitterId, isOffer: isOffer}  })
      .pipe(
        catchError(this.errorCatching),
        finalize(this.afterSubscribe))
  };

  bookingList(page: number, startDate?: Date, endDate?: Date, selectedStatuses?: BookingStatus[], bookingsPromoCode?: string, bookingNumber?: number, addressLine?: string){
    const params: {[key: string]: any} = {}
    if (startDate) {
      params['startDate'] = this.startDateToString(startDate);
    }
    if (endDate) {
      params['endDate'] = this.endDateToString(endDate);
    }
    if (selectedStatuses && selectedStatuses.length < 8 && selectedStatuses.length > 0) {
      params['status'] = selectedStatuses;
    }
    if (bookingsPromoCode) {
      params['promoCode'] = bookingsPromoCode;
    }
    if (bookingNumber) {
      params['bookingNumber'] = bookingNumber;
    }
    if (addressLine) {
      params['addressLine'] = addressLine;
    }
    params['page'] = page;
    this.beforeSubscribe()
    return this.http.get<Page<any>>(`${BASE_URL_SERVER}/admin/booking/list`, {params})
      .pipe(
        catchError(this.errorCatching),
        finalize(this.afterSubscribe))
  }

  searchBooking(bookingNumber: any, fetchdetail: any) {
    this.beforeSubscribe()
    return this.http.get<any>(`${BASE_URL_SERVER}/admin/booking/${bookingNumber}`,  {params: {detail: fetchdetail}})
      .pipe(
        catchError(this.errorCatching),
        finalize(this.afterSubscribe))
  }

  fetchChildren(parentId: any) {
    this.beforeSubscribe()
    return this.http.get<BaseResponse<any>>(`${BASE_URL_SERVER}/admin/parent/child/${parentId}`)
      .pipe(
        catchError(this.errorCatching),
        finalize(this.afterSubscribe))
  }

  fetchAddresses(parentId: any) {
    this.beforeSubscribe()
    return this.http.get<BaseResponse<any>>(`${BASE_URL_SERVER}/admin/parent/fetch/addresses/${parentId}`)
      .pipe(
        catchError(this.errorCatching),
        finalize(this.afterSubscribe))
  }

  fetchCarer (carerId: number) {
    this.beforeSubscribe()
    return this.http.get<any>(`${BASE_URL_SERVER}/admin/carer/${carerId}`)
      .pipe(
        catchError(this.errorCatching),
        finalize(this.afterSubscribe))
  };

deleteReview = (reviewId: any) => {
  return this.http.post<BaseResponse<any>>(`${BASE_URL_SERVER}/admin/booking/review/delete/${reviewId}`, {})
    .pipe(
      map(this.successProcess('deleteReview', 'success')),
      catchError(this.errorCatching),
      finalize(this.afterSubscribe))

};

deleteReference = (referenceId: any) => {
  return this.http.get<BaseResponse<any>>(`${BASE_URL_SERVER}/reference/remove/${referenceId}`)
    .pipe(
      map(this.successProcess('Reference removing', 'Reference was removed successfully')),
      catchError(this.errorCatching),
      finalize(this.afterSubscribe))

};

assignSitter = (bookingId: any, sitterId: any) => {
  this.beforeSubscribe()
  return this.http.post<BaseResponse<any>>(`${BASE_URL_SERVER}/admin/booking/assigncarer`, {},{params: {bookingId: bookingId, carerId: sitterId}  })
    .pipe(
      map(this.successProcess('Sitter assign', `Sitter #'${sitterId}' is assigned successfully`)),
      catchError(this.errorCatching),
      finalize(this.afterSubscribe))
};

  fetchRefrences(sitterId: any) {
    this.beforeSubscribe()
    return this.http.get<any>(`${BASE_URL_SERVER}/admin/carer/${sitterId}/references`)
      .pipe(
        catchError(this.errorCatching),
        finalize(this.afterSubscribe))
  }

  addBlackListedSitterForParent = (parentId: any, sitterId: any) => {
    this.beforeSubscribe()
    return this.http.post<BaseResponse<any>>(`${BASE_URL_SERVER}/admin/parent/blocks/add`,  {},{params: {parentId: parentId, carerId: sitterId}  })
      .pipe(
        map(this.successProcess('addBlackListedSitterForParent', 'success')),
        catchError(this.errorCatching),
        finalize(this.afterSubscribe))
  };

  removeBlackListedSitterForParent = (parentId: any, sitterId: any) => {
    this.beforeSubscribe()
    return this.http.post<BaseResponse<any>>(`${BASE_URL_SERVER}/admin/parent/blocks/remove`, {}, {params: {parentId: parentId, carerId: sitterId}  })
      .pipe(
        map(this.successProcess('removeBlackListedSitterForParent', 'success')),
        catchError(this.errorCatching),
        finalize(this.afterSubscribe))
  };

  addFavouriteSitterForParent = (parentId: any, sitterId: any) => {
    this.beforeSubscribe()
    return this.http.post<BaseResponse<any>>(`${BASE_URL_SERVER}/admin/parent/favorites/add`, {},{params: {parentId: parentId, carerId: sitterId}  })
      .pipe(
        map(this.successProcess('addFavouriteSitterForParent', 'success')),
        catchError(this.errorCatching),
        finalize(this.afterSubscribe))
  };

  removeFavouriteSitterForParent = (parentId: any, sitterId: any) => {
    this.beforeSubscribe()
    return this.http.post<BaseResponse<any>>(`${BASE_URL_SERVER}/admin/parent/favorites/remove`, {},{params: {parentId: parentId, carerId: sitterId}  })
      .pipe(
        map(this.successProcess('removeFavouriteSitterForParent', 'success')),
        catchError(this.errorCatching),
        finalize(this.afterSubscribe))
  };

  editAppData = (reqObject: any) => {
    var body = JSON.stringify(reqObject);
    this.beforeSubscribe()
    return this.http.post<BaseResponse<any>>(`${BASE_URL_SERVER}/admin/booking/edit/appData`, body)
      .pipe(
        map(this.successProcess('editAppData', 'success')),
        catchError(this.errorCatching),
        finalize(this.afterSubscribe))
  };

  changeEmailIdForWronglyRegisteredCarer = (parentId: any) => {
    this.beforeSubscribe()
    return this.http.post<BaseResponse<any>>(`${BASE_URL_SERVER}/admin/parent/changeEmailIdForWronglyRegisteredCarer`, {},{params: {parentId: parentId}  })
      .pipe(
        map(this.successProcess('changeEmailIdForWronglyRegisteredCarer', 'success')),
        catchError(this.errorCatching),
        finalize(this.afterSubscribe))
  };

  fetchParentReviews = (parentId: any) => {
    this.beforeSubscribe()
    return this.http.get<BaseResponse<any>>(`${BASE_URL_SERVER}/admin/parent/reviews`, {params: {parentId: parentId}  })
      .pipe(
        catchError(this.errorCatching),
        finalize(this.afterSubscribe))
  };

  fetchParentBookings = (params: any) => {
    this.beforeSubscribe()
    return this.http.get<BaseResponse<any>>(`${BASE_URL_SERVER}/admin/parent/bookings`, {params: params})
      .pipe(
        catchError(this.errorCatching),
        finalize(this.afterSubscribe))
  };

  fetchChildInfo = (parentId: any, childId: any) => {
    this.beforeSubscribe()
    return this.http.get<any>(`${BASE_URL_SERVER}/admin/parent/child/${parentId}/${childId}`)
      .pipe(
        catchError(this.errorCatching),
        finalize(this.afterSubscribe))
  };

  fetchParentReferredUserList = (parentId: any) => {
    this.beforeSubscribe()
    return this.http.get<any>(`${BASE_URL_SERVER}/admin/parent/child`, {params: {parentId: parentId}  })
      .pipe(
        catchError(this.errorCatching),
        finalize(this.afterSubscribe))
  };


  fetchSitterOpenRecurringBookings() {
    this.beforeSubscribe()
    return this.http.get<any>(`${BASE_URL_SERVER}/admin/booking/recurring/open`)
      .pipe(
        catchError(this.errorCatching),
        finalize(this.afterSubscribe))
  };
  updateRecurringRequestStatus(reqObject: any) {
    const body = JSON.stringify(reqObject);
    this.beforeSubscribe()
    return this.http.post<BaseResponse<any>>(`${BASE_URL_SERVER}/admin/booking/recurringstatus/update`, body,{})
      .pipe(
        map(this.successProcess('updateRecurringRequestStatus', 'success')),
        catchError(this.errorCatching),
        finalize(this.afterSubscribe))
  };

  updateChildInfo = (childVO: any) => {
    this.beforeSubscribe()
    return this.http.post<BaseResponse<any>>(`${BASE_URL_SERVER}/admin/parent/child/update`, childVO,{})
      .pipe(
        map(this.successProcess('updateChildInfo', 'success')),
        catchError(this.errorCatching),
        finalize(this.afterSubscribe))
  };

  saveUserAddress =(addressRequest: any, parentId: any, isDefault: any) => {
    console.log('saving parent address for parentId ' + parentId);
    var body = JSON.stringify(addressRequest);
    this.beforeSubscribe()
    return this.http.post<BaseResponse<any>>(`${BASE_URL_SERVER}/admin/parent/addresses/add/${isDefault}`, body,{})
      .pipe(
        map(this.successProcess('saveUserAddress', 'success')),
        catchError(this.errorCatching),
        finalize(this.afterSubscribe))
  };

  makeParentMember = (membershipRequest: any) => {
    var body = JSON.stringify(membershipRequest);
    this.beforeSubscribe()
    return this.http.post<BaseResponse<any>>(`${BASE_URL_SERVER}/admin/parent/makeMember`, body,{})
      .pipe(
        map(this.successProcess('makeParentMember', 'success')),
        catchError(this.errorCatching),
        finalize(this.afterSubscribe))
  };

  cancelMembership = (membershipRequest: any) => {
    var body = JSON.stringify(membershipRequest);
    this.beforeSubscribe()
    return this.http.post<BaseResponse<any>>(`${BASE_URL_SERVER}/admin/parent/cancelMembership`, body, {})
      .pipe(
        map(this.successProcess('cancelMembership', 'success')),
        catchError(this.errorCatching),
        finalize(this.afterSubscribe))
  };

  fetchSitterAvailability(sitterId: any) {
    this.beforeSubscribe()
    return this.http.get<any>(`${BASE_URL_SERVER}/admin/carer/${sitterId}/availability`)
      .pipe(
        catchError(this.errorCatching),
        finalize(this.afterSubscribe))
  }

  fetchSitterCreditBalance(sitterId: any) {
    this.beforeSubscribe()
    return this.http.get<any>(`${BASE_URL_SERVER}/admin/carer/${sitterId}/creditBalanceHistory`)
      .pipe(
        catchError(this.errorCatching),
        finalize(this.afterSubscribe))
  }

  fetchSitterReviews(sitterId: any) {
    this.beforeSubscribe()
    return this.http.get<any>(`${BASE_URL_SERVER}/admin/carer/${sitterId}/reviews`)
      .pipe(
        catchError(this.errorCatching),
        finalize(this.afterSubscribe))
  }

  fetchSitterBookings(sitterId: any, params: any) {
    this.beforeSubscribe()
    return this.http.get<any>(`${BASE_URL_SERVER}/admin/carer/booking/${sitterId}`, {params})
      .pipe(
        catchError(this.errorCatching),
        finalize(this.afterSubscribe))
  }

  initializeCarer() {
    this.beforeSubscribe()
    return this.http.get<any>(`${BASE_URL_SERVER}/admin/carer/create/`)
      .pipe(
        catchError(this.errorCatching),
        finalize(this.afterSubscribe))
  };

  fetchSitterOtherDetails(sitterId: any) {
    this.beforeSubscribe()
    return this.http.get<any>(`${BASE_URL_SERVER}/admin/carer/stats/${sitterId}`)
      .pipe(
        catchError(this.errorCatching),
        finalize(this.afterSubscribe))
  };

  fetchParent = (parentId: any, fetchdetail: any) => {
    this.beforeSubscribe()
    return this.http.get<any>(`${BASE_URL_SERVER}/admin/parent/${parentId}`, {params: {detail: fetchdetail}})
      .pipe(
        catchError(this.errorCatching),
        finalize(this.afterSubscribe))
  };

  fetchBlackListedSitterForParent = (parentId: any) => {
    this.beforeSubscribe()
    return this.http.get<any>(`${BASE_URL_SERVER}/admin/parent/blocks`, {params: {parentId}})
      .pipe(
        catchError(this.errorCatching),
        finalize(this.afterSubscribe))
  };

  fetchFavouriteSitterForParent = (parentId: any) => {
    this.beforeSubscribe()
    return this.http.get<any>(`${BASE_URL_SERVER}/admin/parent/favorites`, {params: {parentId}})
      .pipe(
        catchError(this.errorCatching),
        finalize(this.afterSubscribe))
  };

  fetchMembershipsForParent = (parentId: any) => {
    this.beforeSubscribe()
    return this.http.get<any>(`${BASE_URL_SERVER}/admin/parent/fetchMemberships/${parentId}`)
      .pipe(
        catchError(this.errorCatching),
        finalize(this.afterSubscribe))
  };

  fetchFavouritedParent = (sitterId: any) => {
    this.beforeSubscribe()
    return this.http.get<any>(`${BASE_URL_SERVER}/admin/carer/${sitterId}/favouritedBy`)
      .pipe(
        catchError(this.errorCatching),
        finalize(this.afterSubscribe))
  };

  resetSitterPassword(sitterId: any, newPassword: any, email: any) {
    const params = {
      carerId: sitterId,
      password: newPassword,
      email: email
    }
    this.beforeSubscribe()
    return this.http.post<any>(`${BASE_URL_SERVER}/admin/carer/resetpassword`, {}, {params: params})
      .pipe(
        map(this.successProcess('Reset password', `Sitter password was changed successfully`)),
        catchError(this.errorCatching),
        finalize(this.afterSubscribe))
  };

  resetSitterReliabilityScore(sitterId: any, reliabilityScore: any) {
    const params = {
      carerId: sitterId,
      reliabilityScore: reliabilityScore
    }
    this.beforeSubscribe()
    return this.http.post<any>(`${BASE_URL_SERVER}/admin/carer/updatereliabilityscore`, {}, {params: params})
      .pipe(
        map(this.successProcess('resetSitterReliabilityScore', 'success')),
        catchError(this.errorCatching),
        finalize(this.afterSubscribe))
  };

  resetPromoBalance(sitterId: any, balance: any) {
    const params = {
      carerId: sitterId,
      balance: balance
    }
    this.beforeSubscribe()
    return this.http.post<any>(`${BASE_URL_SERVER}/admin/carer/resetpromobalance`, {}, {params: params})
      .pipe(
        map(this.successProcess('resetPromoBalance', 'Promo code was reseated successfully')),
        catchError(this.errorCatching),
        finalize(this.afterSubscribe))
  };

  removeSitterAvailability(availabilityId: any) {
    const params = {
      availabilityId: availabilityId
    }
    this.beforeSubscribe()
    return this.http.post<any>(`${BASE_URL_SERVER}/admin/carer/availability/remove`, {}, {params: params})
      .pipe(
        map(this.successProcess('removeSitterAvailability', 'success')),
        catchError(this.errorCatching),
        finalize(this.afterSubscribe))
  };

  uploadMedia(file: any, cat: any) {
    this.beforeSubscribe()
    return this.http.post<any>(`${BASE_URL_SERVER}/user/photo/parent/create`, file.slice())
      .pipe(
        catchError(this.errorCatching),
        finalize(this.afterSubscribe))
  }

  uploadMedia2(file: any, cat: any) {
    this.beforeSubscribe()
    return this.http.post<any>(`${BASE_URL_SERVER}/user/photo/parent/create`, file.slice())
      .pipe(
        catchError(this.errorCatching),
        finalize(this.afterSubscribe))
  }

  addSitter(sitter: any, update: boolean) {
    this.beforeSubscribe()
    return this.http.post<any>(`${BASE_URL_SERVER}/admin/carer/add`, sitter)
      .pipe(
        map(this.successProcess(`${update ? 'Update' : 'Create'} sitter`, `Sitter was ${update ? 'updated' : 'created'} successfully`)),
        catchError(this.errorCatching),
        finalize(this.afterSubscribe))
  }

  addBooking(bookingReqObject: any, addressId: number) {
    this.beforeSubscribe()
    return this.http.post<any>(`${BASE_URL_SERVER}/admin/booking/addBooking/${addressId}`, bookingReqObject)
      .pipe(
        map(this.successProcess('Create booking', 'Booking was created successfully')),
        catchError(this.errorCatching),
        finalize(this.afterSubscribe))
  }

  timeZones() {
    this.beforeSubscribe()
    return this.http.get<string[]>(`${BASE_URL_SERVER}/base/time-zone/all`)
      .pipe(
        catchError(this.errorCatching),
        finalize(this.afterSubscribe))
  }

  verifyAddress(postalCode: string) {
    this.beforeSubscribe()
    return this.http.get<any>(`${BASE_URL_SERVER}/admin/booking/verifyAddress/${postalCode}`)
      .pipe(
        catchError(this.errorCatching),
        finalize(this.afterSubscribe)
      )
  }

  private endDateToString(date: Date): string {
    date.setHours(23, 59, 59, 999);
    return this.dateService.dateToStringEST(date) ?? ''
  }

  addSitterReference(sitterId: any, firstName: any, lastName: any, reviewText: any) {
    const params = {
      firstName: firstName,
      lastName: lastName,
      reviewText: reviewText
    }
    this.beforeSubscribe()
    return this.http.post<any>(`${BASE_URL_SERVER}/admin/carer/${sitterId}/addreference`, {}, {params: params})
      .pipe(
        map(this.successProcess('Create booking', 'Sitter reference was added successfully')),
        catchError(this.errorCatching),
        finalize(this.afterSubscribe))
  };

  fetchSitterAvailabilityArea(state: any){
    this.beforeSubscribe()
    return this.http.get<any>(`${BASE_URL_SERVER}/admin/carer/areas/${state}`)
      .pipe(
        catchError(this.errorCatching),
        finalize(this.afterSubscribe))
  };

  saveSitterAvailabilityArea(reqObject: any){
    var body = JSON.stringify(reqObject);
    this.beforeSubscribe()
    return this.http.post<any>(`${BASE_URL_SERVER}/admin/carer/save/area`, body)
      .pipe(
        catchError(this.errorCatching),
        finalize(this.afterSubscribe))
  };

  deleteSitterAvailabilityArea (reqObject: any){
    var body = JSON.stringify(reqObject);
    this.beforeSubscribe()
    return this.http.post<any>(`${BASE_URL_SERVER}/admin/carer/delete/area`, body)
      .pipe(
        catchError(this.errorCatching),
        finalize(this.afterSubscribe))
  };

  fetchZipCodes(reqObject: any){
    var body = JSON.stringify(reqObject);
    this.beforeSubscribe()
    return this.http.post<any>(`${BASE_URL_SERVER}/admin/carer/fetch/zipCodes`, body)
      .pipe(
        catchError(this.errorCatching),
        finalize(this.afterSubscribe))
  };

  private startDateToString(date: Date): string {
    date.setHours(0, 0, 0, 0);
    return this.dateService.dateToStringEST(date) ?? ''
  }

  calculateCost(bookingVO: any) { // applyPromoOrReferralCode
    this.beforeSubscribe()
    return this.http.post<any>(`${BASE_URL_SERVER}/admin/booking/price/calculate`, bookingVO)
      .pipe(
        catchError(this.errorCatching),
        finalize(this.afterSubscribe))
  };
}
