import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {ExperienceType} from "../../../enums/experience-type.enum";
import {InterestType} from "../../../enums/interest-type.enum";
import {PersonalityTrait} from "../../../enums/personality-trait.enum";
import {DashboardService} from "../../../services/dashboard/dashboard.service";
import {Router} from "@angular/router";
import {DatePipe} from "@angular/common";
import {Page} from "../../../models/page.model";
import {Dialog} from "primeng/dialog";
import {DateService} from "../../../services/date.service";

@Component({
  selector: 'app-sitter-search',
  templateUrl: './search-modal.component.html',
  styleUrls: ['./search-modal.component.scss']
})
export class SitterSearchModalComponent {
  @Output() onSelectEvent = new EventEmitter<string>();
  selectedSitter?: any
  display: boolean = false;

  dateErrorMsg: string | null = null;
  errorMsg: string | null = null;
  isLoading: boolean = false;
  sittersList: any[] = [];
  startDate?: Date;
  endDate?: Date;
  totalRecords: number = 0;
  currentPage: number = 0;
  searchByName?: string;
  searchById?: string;
  searchByKeyword?: string;
  searchByZipCode?: string;
  experienceType: ExperienceType[] = [
    ExperienceType.NEWBORN,
    ExperienceType.INFANT,
    ExperienceType.TODDLER,
    ExperienceType.PRESCHOOL,
    ExperienceType.MEDDLE_SCHOOL,
    ExperienceType.TEEN,
    ExperienceType.MULTIPLE_CHILDREN
  ];
  selectedExperienceType: ExperienceType[] = this.experienceType;
  interestType: InterestType[] = [
    InterestType.MUSIC__MOVEMENT ,
    InterestType.ARTS__CRAFTS,
    InterestType.READING__LANGUAGES,
    InterestType.SPORTS,
    InterestType.IMAGINATIVE_PLAY,
    InterestType.COOKING,
    InterestType.PUZZLES__BOARD_GAMES,
    InterestType.SCIENCE,
    InterestType.MATH,
    InterestType.BUILDING,
  ];
  selectedInterestType: InterestType[] = this.interestType;
  personalityTraits: PersonalityTrait[] = [
    PersonalityTrait.PLAYFUL,
    PersonalityTrait.PATIENT,
    PersonalityTrait.CREATIVE,
    PersonalityTrait.DISCIPLINED,
    PersonalityTrait.UNDERSTANDING,
    PersonalityTrait.HEALTHY,
    PersonalityTrait.CONFIDENT,
    PersonalityTrait.FUNNY,
    PersonalityTrait.PRACTICAL,
    PersonalityTrait.ADAPTABLE,
  ];
  selectedPersonalityTraits: PersonalityTrait[] = this.personalityTraits;

  constructor(
    public dashBoardService: DashboardService,
    public router: Router,
    private datePipe: DatePipe,
    private dateService: DateService
  ) { }

  fetch(page?: number) {
    page = page ?? this.currentPage;
    const params: {[key: string]: any} = {}
    if (this.startDate) {
      params['startDate'] = this.startDateToString(this.startDate);
    }
    if (this.endDate) {
      params['endDate'] = this.endDateToString(this.endDate);
    }
    if (this.searchByName) {
      params['name'] = this.searchByName;
    }
    if (this.searchById) {
      params['id'] = this.searchById;
    }
    if (this.searchByKeyword) {
      params['keyword'] = this.searchByKeyword;
    }
    if (this.searchByZipCode) {
      params['zipCodes'] = this.searchByZipCode;
    }
    if (this.selectedInterestType && this.selectedInterestType.length < 8 && this.selectedInterestType.length > 0) {
      params['interest'] = this.selectedInterestType;
    }
    if (this.selectedExperienceType && this.selectedExperienceType.length < 8 && this.selectedExperienceType.length > 0) {
      params['experience'] = this.selectedExperienceType;
    }
    if (this.selectedPersonalityTraits && this.selectedPersonalityTraits.length < 8 && this.selectedPersonalityTraits.length > 0) {
      params['personality'] = this.selectedPersonalityTraits;
    }
    params['page'] = page;

    this.isLoading = true;
    this.dashBoardService.searchSitters(params)
      .subscribe({
        next: (data: Page<any>) => {
          this.isLoading = false;
          this.sittersList = data.content;
          this.totalRecords = data.totalElements;
          this.currentPage = data.pageable.pageNumber;
        },
        error: (error: any) => {
          this.isLoading = false;
          this.errorMsg = 'Oops Unable to Fetch Data!!!';
          if (error.status === 401) {
            this.router.navigateByUrl('/logout');
          }
        }
      });
  };

  showDialog(dialog: Dialog) {
    this.display = true;
  }

  select() {
    this.display = false;
    this.onSelectEvent.emit(this.selectedSitter)
  }

  submit() {
    if (this.selectedSitter) {
      this.onSelectEvent.emit(this.selectedSitter)
    }
  }


  addNewSitter() {
    this.router.navigateByUrl('/dashboard/sitters/add');
  }

  getExperienceTypeName(experienceTypeId: number): string {
    return ExperienceType[experienceTypeId];
  }

  getInterestTypeName(interestTypeId: number): string {
    return InterestType[interestTypeId];
  }

  getPersonalityTraitName(personalityTraitId: number): string {
    return PersonalityTrait[personalityTraitId];
  }

  paginate(event: any) {
    if (event.page != this.currentPage) {
      this.fetch(event.page);
    }
  }

  private endDateToString(date: Date): string {
    date.setHours(23, 59, 59, 999);
    return this.dateService.dateToStringEST(date)
  }

  private startDateToString(date: Date): string {
    date.setHours(0, 0, 0, 0);
    return this.dateService.dateToStringEST(date)
  }
}
