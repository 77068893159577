export enum PersonalityTrait {
  PLAYFUL,
  PATIENT,
  CREATIVE,
  DISCIPLINED,
  UNDERSTANDING,
  HEALTHY,
  CONFIDENT,
  FUNNY,
  PRACTICAL,
  ADAPTABLE,
}
