import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-time-zone-date',
  templateUrl: './time-zone-date.component.html',
  styleUrls: ['./time-zone-date.component.scss']
})
export class TimeZoneDateComponent implements OnInit {

  @Input() date?: Date;

  @Input() localeTimeZone?: string;

  @Input() city?: string;

  get dateDiffFormat(): string {
    if (this.date) {
      const adminDate: Date = new Date(this.date);
      const userDate: Date = new Date(this.userDate);
      if (adminDate.getDay() - userDate.getDay() === 0) {
        return 'hh:mm a'
      }
    }
    return 'MM/dd/yyyy hh:mm a'
  }

  get userDate(): string {
    return this.date ? new Date(this.date).toLocaleString("en-US", {timeZone: this.localeTimeZone}) : '';
  }

  get bookingAdminDiff(): number {
    if (!this.date) {
      return 0;
    }
    return Math.round((new Date(this.userDate).getTime() - new Date(this.date).getTime()) / (1000 * 3600));
  }

  get bookingAdminDiffString(): string {
    const diff = String(this.bookingAdminDiff);

    return diff.includes('-') ? diff : `+${diff}`
  }
  constructor() { }

  ngOnInit(): void {
    new Date()
  }

  getTimeZoneByLocaleTimeZone(localeTimeZone: string): string {
    switch (localeTimeZone) {
      case 'America/New_York':
        return ' EST';
      case 'America/Chicago':
        return ' CST';
      default:
        return localeTimeZone
    }
  }
}
