import {Component, OnDestroy, OnInit} from '@angular/core';
import { MenuItem } from 'primeng/api';
import {Router} from "@angular/router";
import {AuthService} from "../../auth/auth.service";
import {NavbarComponent} from "../navbar/navbar.component";

@Component({
  selector: 'top-bar',
  templateUrl: './top-bar.component.html',
  styleUrls: ['./top-bar.component.scss']
})
export class TopBarComponent implements OnInit{

  items?: MenuItem[];
  adminConsoleDate: Date = new Date()

  constructor(
    public app: NavbarComponent,
    private router: Router,
    public authService: AuthService
  ) { }

  ngOnInit() {
    setInterval(() => {
      this.adminConsoleDate = new Date();
    }, 1000);
  }

  logout(): void {
    this.authService.logout();
    this.router.navigate(['login']);
  }
}
