import { Component, OnInit } from '@angular/core';
import {NavbarComponent} from "../navbar/navbar.component";

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html'
})
export class MenuComponent implements OnInit {

  model = [
    {
      label: '',
      items:[
        {label: 'User Stats',icon: 'pi pi-fw pi-chart-bar', routerLink: '/dashboard/stats/user'},
        {label: 'Carer Stats',icon: 'pi pi-fw pi-chart-line', routerLink: '/dashboard/stats/carer'},
        {label: 'Booking Stats',icon: 'pi pi-fw pi-chart-pie', routerLink: '/dashboard/stats/booking'},
        {label: 'Promo Code',icon: 'pi pi-fw pi-tag', routerLink: '/dashboard/promo-code'},
        {label: 'Bookings',icon: 'pi pi-fw pi-book', routerLink: '/dashboard/booking'},
        {label: 'Booking',icon: 'pi pi-fw pi-plus', routerLink: '/dashboard/booking/edit'},
        {label: 'Sitters',icon: 'pi pi-fw pi-user', routerLink: '/dashboard/sitter'},
        {label: 'Sitter',icon: 'pi pi-fw pi-plus', routerLink: '/dashboard/sitter/edit'},
        {label: 'Parents',icon: 'pi pi-fw pi-user', routerLink: '/dashboard/parent'},
        {label: 'Surge Prices',icon: 'pi pi-fw pi-money-bill', routerLink: '/dashboard/surge'},
        {label: 'Service City',icon: 'pi pi-fw pi-building', routerLink: '/dashboard/service-city', badge: 'new'},
        {label: 'Price Rule',icon: 'pi pi-fw pi-money-bill', routerLink: '/dashboard/price-rule', badge: 'new'},
        {label: 'Service Area',icon: 'pi pi-fw pi-circle', routerLink: '/dashboard/service-area'},
        {label: 'Notifications',icon: 'pi pi-fw pi-send', routerLink: '/dashboard/notification'},
        {label: 'Recurring Bookings',icon: 'pi pi-fw pi-circle', routerLink: '/dashboard/recurring-booking'},
        // {label: 'Updates',icon: 'pi pi-fw pi-arrow-circle-up', routerLink: '/dashboard/update', badge: 'new'},
      ]
    },
  ];

  constructor(public app: NavbarComponent) { }

  ngOnInit() {}

  onKeydown(event: KeyboardEvent) {
    const nodeElement = (<HTMLDivElement> event.target);
    if (event.code === 'Enter' || event.code === 'Space') {
      nodeElement.click();
      event.preventDefault();
    }
  }
}
