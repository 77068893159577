<!--<div class="card mb-0">-->
  Sitter
  <div *ngIf="!sitter" class="text-900 font-medium text-xl">NOT FOUND</div>
  <div *ngIf="sitter" class="flex">
    <div style="width: 200px" class="pr-3">
      <img [src]="sitter.imageURL.length < 5 ? imageURL : sitter.imageURL" [alt]="sitter.firstName"
           class="shadow-4" width="100%"/>
    </div>
    <div class="flex">
      <div style="width: 100px">
        <p style="font-weight: bolder">ID</p>
        <p>First Name</p>
        <p>Last Name</p>
        <p>Number</p>
        <p>Email</p>
      </div>
      <div>
        <p> <a [routerLink]="['/' ,'dashboard', 'sitter', 'detail', sitter.id]"> #{{sitter.id}} </a> </p>
        <p> {{sitter.firstName}} </p>
        <p> {{sitter.lastName}} </p>
        <p> {{sitter.phoneNumber}} </p>
        <p> {{sitter.email}} </p>
      </div>
    </div>
  </div>
<!--</div>-->
