import {Injectable} from '@angular/core';
import {DatePipe} from "@angular/common";
import * as moment from 'moment-timezone';

@Injectable({
  providedIn: 'root'
})
export class DateService {

  constructor(private datePipe: DatePipe) { }

  dateToStringEST(date: Date): string {
    const offset = this.timeZoneOffset(date, 'America/New_York');
    return this.datePipe.transform(date, 'yyyy-MM-dd\'T\'HH:mm:ss') + '.000' + offset ?? '';
  }


  dateToStringByTimeZone(date: Date, timeZone: string): string {
    const offset = this.timeZoneOffset(new Date(), timeZone);
    return this.datePipe.transform(date, 'yyyy-MM-dd\'T\'HH:mm:ss') + '.000' + offset ?? '';
  }

  timeZoneShort(timeZone: string) {
    switch (timeZone) {
      case 'Central Standard Time':
        return 'America/Chicago'
      case 'Eastern Standard Time':
        return 'America/New_York'
      default:
        return 'America/New_York'
    }
  }

  timeZoneOffset(date: Date, timeZone: string) {
    const timeZoneShort = this.timeZoneShort(timeZone);
    const dateInput2 = this.datePipe.transform(date, 'yyyy-MM-dd');
    const offset2b = moment.tz(dateInput2, timeZoneShort).format('ZZ');
    console.warn( timeZone + ': ' + offset2b);
    return offset2b;
  }
}
