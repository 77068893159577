import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-sitter-small-detail',
  templateUrl: './small-detail.component.html',
  styleUrls: ['./small-detail.component.scss']
})
export class SitterSmallDetailComponent {
  @Input() sitter?: any;
  imageURL: string = 'https://thumbs.dreamstime.com/b/default-avatar-profile-trendy-style-social-media-user-icon-187599373.jpg';

}
