<form (keyup.enter)="login()" (ngSubmit)="login()" [formGroup]="form" class="container">
  <section class="login">
    <div class="login_box">
      <div class="left">
        <div class="contact">
          <div>
            <h4> Hello Sitter | Admin Panel </h4>
            <small style="color: red; font-style: italic;" *ngIf="errorMessage">{{errorMessage}}</small>

            <input (change)="errorMessage = null" style="margin-bottom: 0;" autofocus formControlName="login" type="text" placeholder="LOGIN" required >
            <small style="color: red; font-style: italic;" *ngIf="form.controls['login'].invalid && (form.controls['login'].dirty || form.controls['login'].touched)">Login is required.</small>

            <input (change)="errorMessage = null" style="margin-bottom: 0;" placeholder="PASSWORD" formControlName="password" type="password" required >
            <small style="color: red; font-style: italic;" *ngIf="form.controls['password'].invalid && (form.controls['password'].dirty || form.controls['password'].touched)">Password is required.</small>

            <button [disabled]="isLoginDisabled" class="submit">SIGN IN</button>
          </div>
        </div>
      </div>
      <div class="right">
        <div class="right-text">
<!--          <h2>Hello peace of mind.</h5>-->
<!--          <h5>Welcome to the app that’s changing what it means to find the right babysitter in New York.-->
<!--            Now serving New Orleans.</h5>-->
        </div>
      </div>
    </div>
  </section>
</form>
