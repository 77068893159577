import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'age'
})
export class AgePipe implements PipeTransform {

  transform(value: string): string {
    const today = new Date();
    const birthDate = new Date(value);
    let diffYears = today.getFullYear() - birthDate.getFullYear();
    let diffMonths = (today.getMonth() - 1) - birthDate.getMonth();
    const diffDays = Math.floor((today.getTime() - birthDate.getTime()) / 1000 / 60 / 60 / 24);

    if (diffYears <= 0) {
      if (diffMonths > 0) {
        return `${diffMonths} month, ${Math.floor(diffDays/7)} weeks`;
      } else {
        return `${Math.floor(diffDays/7)} weeks, ${diffDays%7} days `;
      }
    }

    if (diffMonths < 0) {
      diffYears--;
      diffMonths = 12 + diffMonths;
    }

    return `${diffYears} years, ${diffMonths} months`;
  }

}
