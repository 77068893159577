<div class="layout-wrapper layout-theme-light layout-static" [ngClass]="{
                                'layout-static-sidebar-inactive': staticMenuInactive,
                                'layout-mobile-sidebar-active':menuActiveMobile
                                }">
  <top-bar></top-bar>
  <div class="layout-sidebar">
    <app-menu ></app-menu>
  </div>
  <div class="layout-main-container">
    <div class="layout-main">
      <router-outlet></router-outlet>
    </div>
    <app-footer></app-footer>
  </div>
  <div *ngIf="menuActiveMobile" class="layout-mask p-component-overlay"></div>
</div>
