<div class="layout-footer">
<!--  <img src=assets/img/hello-sitter-logo.png" alt="Logo" height="20" class="mr-2"/>-->

    <div class="mr-2">
      <img src="assets/img/hello-sitter-logo.png"  height="20" class="mr-2" alt="logo">
    </div>
<!--    <div>-->
<!--      Created by:-->
<!--      <span class="font-medium ml-2 mr-2">Implemica</span>-->
<!--    </div>-->
    <div>
      Version:
      <span class="font-medium ml-2 mr-2">04/21/2024:v1</span>
    </div>
    <div>
      Status:
      <span class="font-medium ml-2 mr-2">{{ENVIRONMENT_NAME}}</span>
    </div>
</div>
