import { Injectable } from '@angular/core';
import {shareReplay} from 'rxjs/operators';
import {HttpClient, HttpHeaders,} from '@angular/common/http';
import { Router } from '@angular/router';
import {BaseResponse} from "../models/base-response.model";
import {Token} from "../models/token.model";
import {BASE_URL_SERVER} from "../../environments/environment";


@Injectable({
  providedIn: 'root'
})
export class AuthService {
  headers = new HttpHeaders().set('Content-Type', 'application/json');

  constructor(
    private http: HttpClient,
    public router: Router,
  ) {}

  login(email:string, password:string, error: (err: string) => void) {
    const params = {userName: email, password};
    return this.http.get<BaseResponse<Token>>(`${BASE_URL_SERVER}/admin/auth/login`, { headers: this.headers, params })
      .pipe(shareReplay(1))
      .subscribe({
        next: (res: BaseResponse<Token>) => {
          const data = res.data;
          if (data && data.token) {
            localStorage.setItem('token', data.token);
            this.router.navigate(['/']);
          } else {
            error('Incorrect login or password')
          }
        },
        error: err => {
          error('Incorrect login or password')
        }
      });
  }

  getToken() {
    return localStorage.getItem('token');
  }

  get isAuthenticated(): boolean {
    return localStorage.getItem('token') !== null;
  }

  logout() {
    localStorage.removeItem('token');
    this.router.navigate(['/login']);
  }
}
