<!--<div class="card mb-0">-->
  Parent
  <div *ngIf="!parent" class="text-900 font-medium text-xl">NOT FOUND</div>
  <div *ngIf="parent" class="flex">
    <div style="width: 200px" class="pr-3">
      <img [src]="parent.imageURL.length < 5 ? imageURL : parent.imageURL" [alt]="parent.firstName"
           class="shadow-4" width="100%"/>
    </div>
    <div class="flex">
      <div style="width: 100px">
        <p style="font-weight: bolder">ID</p>
        <p>First Name</p>
        <p>Last Name</p>
        <p>Number</p>
        <p>Email</p>
      </div>
      <div>
        <p> <a [routerLink]="['/' ,'dashboard', 'parent', 'detail', parent.id]"> #{{parent.id}} </a> </p>
        <p> {{parent.firstName}} </p>
        <p> {{parent.lastName}} </p>
        <p> {{parent.phoneNumber}} </p>
        <p> {{parent.email}} </p>
      </div>
    </div>
  </div>
<!--</div>-->
