import {Component, Input, OnInit} from '@angular/core';
import {DashboardService} from "../../../../services/dashboard/dashboard.service";

@Component({
  selector: 'app-sitter-small-detail-overlay',
  templateUrl: './small-detail-overlay.component.html',
  styleUrls: ['./small-detail-overlay.component.scss']
})
export class SitterSmallDetailOverlayComponent implements OnInit {

  @Input() id?: any;
  sitter?: any

  constructor(private dashboardService: DashboardService) { }

  ngOnInit(): void {
  }

  loadSitter() {
    this.dashboardService
      .fetchCarer(this.id)
      .subscribe(resp => {
        this.sitter = resp.data
      })
  }

}
