import {Component, Input, OnInit} from '@angular/core';
import {DashboardService} from "../../../services/dashboard/dashboard.service";
import {ActivatedRoute, Route, Router} from "@angular/router";
import {Location} from "@angular/common";

@Component({
  selector: 'app-parent-small-detail',
  templateUrl: './small-detail.component.html',
  styleUrls: ['./small-detail.component.scss']
})
export class ParentSmallDetailComponent {
  @Input() parent?: any;
  imageURL: string = 'https://thumbs.dreamstime.com/b/default-avatar-profile-trendy-style-social-media-user-icon-187599373.jpg';

}
