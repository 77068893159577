<p-button (click)="showDialog(dialog); fetch(0)" label="Find sitter" icon="pi pi-search"></p-button>


<!-- MODAL PART-->
<p-dialog #dialog header="Search parent" [(visible)]="display" [style]="{width: '1000px'}" [maximizable]="true" [baseZIndex]="500"
          [draggable]="false" [resizable]="false">
  <ng-template pTemplate="header">
    <h2 class="text-muted text-xs-center">Select Sitter</h2>
  </ng-template>
  <ng-template pTemplate="content">

    <div class="pb-3 d-flex">
      <div class="mr-3">
        <div>Sitter Name:</div>
        <span class="p-float-label">
          <input type="text" [(ngModel)]="searchByName" pInputText>
        </span>
      </div>
      <div class="mr-3">
        <div>Sitter Id:</div>
        <span class="p-float-label">
          <input type="text" [(ngModel)]="searchById" pInputText>
        </span>
      </div>
      <div class="mr-3">
        <div>Keyword:</div>
        <span class="p-float-label">
          <input type="text" [(ngModel)]="searchByKeyword" pInputText>
        </span>
      </div>
      <!--      <div class="mr-3">-->
      <!--        <div>Zip code:</div>-->
      <!--        <span class="p-float-label">-->
      <!--          <input type="text" [(ngModel)]="searchByZipCode" pInputText>-->
      <!--        </span>-->
      <!--      </div>-->

      <div class="mt-4 mr-3">
        <button (click)="fetch()" pButton class="p-button-outlined ml-3" icon="pi pi-search"></button>
      </div>
    </div>

    <div class="pb-3 d-flex">
      <div class="mr-3">
        <div>Experience Type:</div>
        <p-multiSelect [style]="{'width': '100%'}" [options]="experienceType" placeholder="Select options"
                       [(ngModel)]="selectedExperienceType">
          <ng-template pTemplate="selectedItems">
            <div *ngIf="selectedExperienceType.length > 0 && selectedExperienceType.length < experienceType.length">
              {{selectedExperienceType.join(', ')}}
            </div>
            <div *ngIf=" selectedExperienceType.length === 0">
              None selected
            </div>
            <div *ngIf="selectedExperienceType.length === experienceType.length">
              All selected
            </div>
          </ng-template>

          <ng-template let-statusNum pTemplate="item">
            <div>
              <div> {{getExperienceTypeName(statusNum)}} </div>
            </div>
          </ng-template>
        </p-multiSelect>
      </div>

      <div class="mr-3">
        <div>Interest Type:</div>
        <p-multiSelect [style]="{'width': '100%'}" [options]="interestType" placeholder="Select options"
                       [(ngModel)]="selectedInterestType">
          <ng-template pTemplate="selectedItems">
            <div *ngIf="selectedInterestType.length > 0 && selectedInterestType.length < interestType.length">
              {{selectedInterestType.join(', ')}}
            </div>
            <div *ngIf=" selectedInterestType.length === 0">
              None selected
            </div>
            <div *ngIf="selectedInterestType.length === interestType.length">
              All selected
            </div>
          </ng-template>

          <ng-template let-statusNum pTemplate="item">
            <div>
              <div> {{getInterestTypeName(statusNum)}} </div>
            </div>
          </ng-template>
        </p-multiSelect>
      </div>

      <div class="mr-3">
        <div>Personality Trait:</div>
        <p-multiSelect [style]="{'width': '100%'}" [options]="personalityTraits" placeholder="Select options"
                       [(ngModel)]="selectedPersonalityTraits">
          <ng-template pTemplate="selectedItems">
            <div *ngIf="selectedPersonalityTraits.length > 0 && selectedPersonalityTraits.length < personalityTraits.length">
              {{selectedPersonalityTraits.join(', ')}}
            </div>
            <div *ngIf=" selectedPersonalityTraits.length === 0">
              None selected
            </div>
            <div *ngIf="selectedPersonalityTraits.length === personalityTraits.length">
              All selected
            </div>
          </ng-template>

          <ng-template let-statusNum pTemplate="item">
            <div>
              <div> {{getPersonalityTraitName(statusNum)}} </div>
            </div>
          </ng-template>
        </p-multiSelect>
      </div>

    </div>

    <table class="table table-hover">
      <thead class="grey-colour">
      <tr>
        <th>Sitter Id</th>
        <th>Last
          Name</th>
        <th>First
          Name</th>
        <th>Phone</th>
        <th>City</th>
        <th>Email</th>
        <th>Requested</th>
        <th>Accepted</th>
        <th>Declined</th>
        <th>Completed</th>
        <th>Rating</th>
      </tr>
      </thead>
      <tbody *ngIf="!isLoading">
      <tr
        *ngFor="let sitter of sittersList; let i = index;"
        [ngStyle]="{'background-color': (sitter === selectedSitter) ? '#73c7af' : '#ffffff'}" (click)="selectedSitter = sitter">
        <td><a [routerLink]="['/dashboard', 'sitter' ,'detail', sitter.id]">{{sitter.id}}</a></td>
        <td>{{sitter.lastName}}</td>
        <td>{{sitter.firstName}}</td>
        <td>{{sitter.phoneNumber}}</td>
        <td>{{sitter?.serviceCity}}</td>
        <td>{{sitter.email}}</td>
        <td>{{sitter?.carerStatVO?.bookingsRequested}}</td>
        <td>{{sitter?.carerStatVO?.bookingsAccepted}}</td>
        <td>{{sitter?.carerStatVO?.bookingsDeclined}}</td>
        <td>{{sitter?.carerStatVO?.completedBookings}}</td>
        <td>{{sitter?.rating}}</td>
      </tr>
      </tbody>
    </table>


    <div class="flex justify-content-center">
      <p-paginator (onPageChange)="paginate($event)" #paginator [rows]="10" [totalRecords]="totalRecords"></p-paginator>
    </div>


  </ng-template>
  <ng-template pTemplate="footer">
    <p-button [disabled]="!selectedSitter" (click)="select()" label="Select" icon="pi pi-check"></p-button>
  </ng-template>
</p-dialog>
