import {RouterModule} from "@angular/router";
import {NgModule} from "@angular/core";
import {DASHBOARD_ROUTE} from "./modules/dashboard/dashboard.route";
import {LoginComponent} from "./components/login/login.component";

@NgModule({
  imports: [
    RouterModule.forRoot(
      [
        {
          path: '',
          pathMatch: 'full',
          redirectTo: 'dashboard',
        },
        {path:'login', component: LoginComponent},
        ...DASHBOARD_ROUTE,
      ],
    ),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
