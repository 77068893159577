import {NgModule} from "@angular/core";
import {AgePipe} from "../pipes/age.pipe";
import { TimeZoneDateComponent } from './time-zone-date/time-zone-date/time-zone-date.component';
import {CommonModule, DatePipe} from "@angular/common";
import {OverlayPanelModule} from "primeng/overlaypanel";
import {ProgressSpinnerModule} from "primeng/progressspinner";
import {RippleModule} from "primeng/ripple";
import {DividerModule} from "primeng/divider";
import {DialogModule} from "primeng/dialog";
import {SitterSmallDetailComponent} from "./sitter/small-detail/small-detail.component";
import {SitterSearchModalComponent} from "./sitter/search-modal/search-modal.component";
import {CalendarModule} from "primeng/calendar";
import {InputTextModule} from "primeng/inputtext";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {MultiSelectModule} from "primeng/multiselect";
import {PaginatorModule} from "primeng/paginator";
import {RouterModule} from "@angular/router";
import {ParentSearchModalComponent} from "./parent/search-modal/search-modal.component";
import {ParentSmallDetailComponent} from "./parent/small-detail/small-detail.component";
import {TabViewModule} from "primeng/tabview";
import {ToastModule} from "primeng/toast";
import {ParentSmallDetailOverlayComponent} from './parent/small-detail/small-detail-overlay/small-detail-overlay.component';
import {SitterSmallDetailOverlayComponent} from "./sitter/small-detail/small-detail-overlay/small-detail-overlay.component";
import {ConfirmPopupModule} from "primeng/confirmpopup";
import {PanelModule} from "primeng/panel";
import {CheckboxModule} from "primeng/checkbox";
import {RadioButtonModule} from "primeng/radiobutton";
import {InputTextareaModule} from "primeng/inputtextarea";
import {AutoCompleteModule} from "primeng/autocomplete";
import {FontAwesomeModule} from "@fortawesome/angular-fontawesome";
import {NgbModule} from "@ng-bootstrap/ng-bootstrap";
import {SpinnerComponent} from "./ux/spinner.component";
import { ChipsModule } from 'primeng/chips';
import {ImageModule} from "primeng/image";
import {SelectButtonModule} from "primeng/selectbutton";
import {TableModule} from "primeng/table";

@NgModule({
  imports: [
    CommonModule,
    OverlayPanelModule,
    ProgressSpinnerModule,
    RippleModule,
    DividerModule,
    DialogModule,
    CalendarModule,
    FormsModule,
    InputTextModule,
    MultiSelectModule,
    PaginatorModule,
    RouterModule,
    TabViewModule,
    ConfirmPopupModule,
    PanelModule,
    DialogModule,
    CheckboxModule,
    RadioButtonModule,
    InputTextareaModule,
    MultiSelectModule,
    TabViewModule,
    OverlayPanelModule,
    CalendarModule,
    InputTextModule,
    PaginatorModule,
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    AutoCompleteModule,
    FontAwesomeModule,
    NgbModule,
    ChipsModule,
    ImageModule,
    DividerModule,
    SelectButtonModule,
    TableModule,
  ],
  declarations: [
    AgePipe,
    TimeZoneDateComponent,
    SitterSearchModalComponent,
    SitterSmallDetailComponent,
    ParentSearchModalComponent,
    ParentSmallDetailComponent,
    SitterSmallDetailOverlayComponent,
    ParentSmallDetailOverlayComponent,
    SpinnerComponent,
  ],
  exports: [
    AgePipe,
    TimeZoneDateComponent,
    ProgressSpinnerModule,
    RippleModule,
    DialogModule,
    SitterSearchModalComponent,
    SitterSmallDetailComponent,
    CalendarModule,
    FormsModule,
    InputTextModule,
    MultiSelectModule,
    PaginatorModule,
    ProgressSpinnerModule,
    ParentSearchModalComponent,
    ParentSmallDetailComponent,
    TabViewModule,
    SitterSmallDetailOverlayComponent,
    ParentSmallDetailOverlayComponent,
    ToastModule,
    ConfirmPopupModule,
    PanelModule,
    DialogModule,
    CheckboxModule,
    RadioButtonModule,
    InputTextareaModule,
    MultiSelectModule,
    TabViewModule,
    OverlayPanelModule,
    CalendarModule,
    InputTextModule,
    PaginatorModule,
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    AutoCompleteModule,
    FontAwesomeModule,
    NgbModule,
    SpinnerComponent,
    ChipsModule,
    ImageModule,
    DividerModule,
    SelectButtonModule,
    TableModule,
  ]
})
export class SharedModule {}
