import {Routes} from "@angular/router";
import {STATS_ROUTE} from "./stats/stats.route";
import {NavbarComponent} from "../../components/navbar/navbar.component";
import {AuthGuard} from "../../auth/auth,guard";

export const DASHBOARD_ROUTE: Routes = [
  {
    path: 'dashboard',
    component: NavbarComponent,
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'stats',
      },
      ...STATS_ROUTE,
      {
        path: 'promo-code',
        loadChildren: () => import('./promo-code/promo-code.module').then(module => module.PromoCodeModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'booking',
        loadChildren: () => import('./booking/booking.module').then(module => module.BookingModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'sitter',
        loadChildren: () => import('./sitter/sitter.module').then(module => module.SitterModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'parent',
        loadChildren: () => import('./parent/parent.module').then(module => module.ParentModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'service-area',
        loadChildren: () => import('./service-area/service-area.module').then(module => module.ServiceAreaModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'notification',
        loadChildren: () => import('./notification/notification.module').then(module => module.NotificationModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'recurring-booking',
        loadChildren: () => import('./recurring-booking/recurring-booking.module').then(module => module.RecurringBookingModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'update',
        loadChildren: () => import('./update/update.module').then(module => module.UpdateModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'surge',
        loadChildren: () => import('./surge-price/surge-price-list.module').then(module => module.SurgePriceModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'service-city',
        loadChildren: () => import('./service-city/service-city.module').then(module => module.ServiceCityModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'price-rule',
        loadChildren: () => import('./price-rule/price-rule.module').then(module => module.PriceRuleModule),
        canActivate: [AuthGuard]
      },
    ]
  }
];
