import {Component, EventEmitter, Output} from '@angular/core';
import {Page} from "../../../models/page.model";
import {DashboardService} from "../../../services/dashboard/dashboard.service";
import {Router} from "@angular/router";
import {DatePipe} from "@angular/common";
import {DateService} from "../../../services/date.service";

@Component({
  selector: 'app-parent-search',
  templateUrl: './search-modal.component.html',
  styleUrls: ['./search-modal.component.scss']
})
export class ParentSearchModalComponent {
  @Output() onSelectEvent = new EventEmitter<string>();
  dateErrorMsg: string | null = null;
  errorMsg: string | null = null;
  isLoading: boolean = false;
  parentList: any[] = [];
  selectedParent?: any
  totalRecords: number = 0;
  currentPage: number = 0;
  targetArea?: number;


  searchByName?: string;
  searchById?: string;
  searchByKeyword?: string;
  startDate?: Date;
  endDate?: Date;

  display: boolean = false;

  constructor(
    public dashBoardService: DashboardService,
    public router: Router,
    private dateService: DateService
  ) { }

  showDialog() {
    this.display = true;
  }

  select() {
    this.display = false;
    this.onSelectEvent.emit(this.selectedParent)
  }

  fetch(page?: number) {
    page = page ?? this.currentPage;
    const params: {[key: string]: any} = {}
    if (this.startDate) {
      params['startDate'] = this.startDateToString(this.startDate);
    }
    if (this.endDate) {
      params['endDate'] = this.endDateToString(this.endDate);
    }
    if (this.searchByName) {
      params['name'] = this.searchByName;
    }
    if (this.searchById) {
      params['id'] = this.searchById;
    }
    if (this.searchByKeyword) {
      params['keyword'] = this.searchByKeyword;
    }
    if (this.targetArea) {
      params['targetArea'] = this.targetArea;
    }
    params['page'] = page;

    this.isLoading = true;
    this.dashBoardService.searchParents(params)
      .subscribe({
        next: (data: any) => {
          this.isLoading = false;
          this.parentList = data.content;
          this.totalRecords = data.totalElements;
          this.currentPage = data.pageable.pageNumber;
        },
        error: (error: any) => {
          this.isLoading = false;
          this.errorMsg = 'Oops Unable to Fetch Data!!!';
          if (error.status === 401) {
            this.router.navigateByUrl('/logout');
          }
        }
      });
  };

  submit() {
    if (this.selectedParent) {
      this.onSelectEvent.emit(this.selectedParent)
    }
  }

  private endDateToString(date: Date): string {
    date.setHours(23, 59, 59, 999);
    return this.dateService.dateToStringEST(date)
  }

  private startDateToString(date: Date): string {
    date.setHours(0, 0, 0, 0);
    return this.dateService.dateToStringEST(date)
  }

  paginate(event: any) {
    if (event.page != this.currentPage) {
      this.fetch(event.page);
    }
  }
}
