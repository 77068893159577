<ng-container>
  <a
    [routerLink]="item.routerLink"
    [attr.href]="item.url"
    (click)="itemClick($event)"
    [ngClass]="item.class"
    *ngIf="(!item.routerLink || item.items) && item.visible !== false"
    [attr.target]="item.target"
    [attr.tabindex]="0"
    [attr.aria-label]="item.label"
    style="font-size: 20px"
    role="menuitem"
    pRipple
  >
    <i [ngClass]="item.icon" class="layout-menuitem-icon"></i>
    <span>{{item.label}}</span>
    <span class="menuitem-badge" *ngIf="item.badge">{{item.badge}}</span>
    <i class="pi pi-fw {{active ? 'pi-angle-up' : 'pi-angle-down'}} ml-auto" *ngIf="item.items"></i>
  </a>
  <a
    (click)="itemClick($event)"
    *ngIf="(item.routerLink && !item.items) && item.visible !== false"
    [ngClass]="item.class"
    [routerLink]="item.routerLink" routerLinkActive="active-menuitem-routerlink router-link-exact-active"
    [routerLinkActiveOptions]="{exact: !item.preventExact}"
    [attr.target]="item.target"
    [attr.tabindex]="0"
    [attr.aria-label]="item.label"
    style="font-size: 20px"
    role="menuitem"
    pRipple
  >
    <i [ngClass]="item.icon" class="layout-menuitem-icon"></i>
    <span>{{item.label}}</span>
    <span class="p-tag p-badge ml-auto" *ngIf="item.badge">{{item.badge}}</span>
    <i class="pi pi-fw {{active ? 'pi-angle-up' : 'pi-angle-down'}} ml-auto" *ngIf="item.items"></i>
  </a>
  <ul *ngIf="(item.items && active) && item.visible !== false" [@children]="(active ? 'visibleAnimated' : 'hiddenAnimated')" role="menu">
    <ng-template let-child let-i="index" [ngForOf]="item.items">
      <app-menu-item [item]="child" [index]="i" [parentKey]="key" [class]="child.badgeClass" role="none"></app-menu-item>
    </ng-template>
  </ul>
</ng-container>
