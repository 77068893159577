import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {

  private loading: boolean = false;

  private loadingCount = 0;

  constructor() { }

  setLoading(loading: boolean) {
    this.loadingCount = loading ? this.loadingCount + 1 : this.loadingCount - 1
    this.loading = this.loadingCount > 0;
  }

  getLoading(): boolean {
    return this.loading;
  }
}
