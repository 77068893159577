<p-button (click)="showDialog(); fetch(0)" label="Find parent" icon="pi pi-search"></p-button>


<!-- MODAL PART-->
<p-dialog header="Search parent" [(visible)]="display" [breakpoints]="{'960px': '75vw', '640px': '100vw'}" [style]="{width: '50vw'}">
  <ng-template pTemplate="header">
    <h2 class="text-muted text-xs-center">Select Parent</h2>
  </ng-template>
  <ng-template pTemplate="content">

    <div class="pb-3 d-flex">
      <div class="mr-3">
        <div> Name:</div>
        <span class="p-float-label">
          <input type="text" [(ngModel)]="searchByName" pInputText>
        </span>
      </div>
      <div class="mr-3">
        <div> Id:</div>
        <span class="p-float-label">
          <input type="text" [(ngModel)]="searchById" pInputText>
        </span>
      </div>
      <div class="mr-3">
        <div>Keyword:</div>
        <span class="p-float-label">
          <input type="text" [(ngModel)]="searchByKeyword" pInputText>
        </span>
      </div>
      <!--      <div class="mr-3">-->
      <!--        <div>Zip code:</div>-->
      <!--        <span class="p-float-label">-->
      <!--          <input type="text" [(ngModel)]="searchByZipCode" pInputText>-->
      <!--        </span>-->
      <!--      </div>-->

      <div class="mt-4 mr-3">
        <button (click)="fetch()" pButton class="p-button-outlined ml-3" icon="pi pi-search"></button>
      </div>
    </div>

    <table class="table table-hover">
    <thead class="grey-colour">
    <tr>
      <th>Parent Id</th>
      <th>Last Name</th>
      <th>First Name</th>
      <th>Phone</th>
      <th>Email</th>
      <th>Children</th>
      <th>Bookings</th>
      <th>Referral code</th>
      <th>In Service Area?</th>
      <th>Active</th>
    </tr>
    </thead>
    <tbody *ngIf="!isLoading">
    <tr
      *ngFor="let parent of parentList; let i = index;" [ngStyle]="{'background-color': (parent === selectedParent) ? '#73c7af' : '#ffffff'}" (click)="selectedParent = parent">
      <td><a [routerLink]="['/dashboard', 'parent' ,'detail', parent.id]">{{parent.id}}</a></td>
      <td>{{parent.lastName}}</td>
      <td>{{parent.firstName}}</td>
      <td>{{parent.phoneNumber}}</td>
      <td>{{parent.email}}</td>
      <td>{{parent?.children.length}}</td>
      <td>{{parent?.completedBookings}}</td>
      <td>{{parent?.referralCode}}</td>
      <td>{{parent?.inServiceArea}}</td>
      <td>{{parent?.active}}</td>
    </tr>
    </tbody>
  </table>


    <div class="flex justify-content-center">
      <p-paginator (onPageChange)="paginate($event)" #paginator [rows]="10" [totalRecords]="totalRecords"></p-paginator>
    </div>
  </ng-template>
  <ng-template pTemplate="footer">
    <p-button [disabled]="!selectedParent" (click)="select()" label="Select" icon="pi pi-check"></p-button>
  </ng-template>
</p-dialog>
