<div class="layout-topbar">
  <a class="layout-topbar-logo" routerLink="">
    <img src="assets/img/hello-sitter-logo.png" alt="logo">
    <span style="font-size: 2rem !important;"> HELLO SITTER </span>
  </a>

  <a class="p-link layout-menu-button layout-topbar-button" href="#" (click)="app.toggleMenu($event)">
    <i class="pi pi-bars"></i>
  </a>

  <a class="p-link layout-topbar-menu-button layout-topbar-button" href="#" (click)="app.toggleTopMenu($event)">
    <i class="pi pi-ellipsis-v"></i>
  </a>

  <div class="layout-topbar-menu" [ngClass]="{'layout-topbar-menu-mobile-active':app.topMenuActive}">
    <p class="p-link pt-2 mt-1">{{adminConsoleDate | date: 'EEEE/LLLL: MM/dd/yyyy hh:mm:ss a'}}</p>
    <div class="layout-topbar-menu">
      <a class="p-link layout-topbar-button" (click)="logout()">
        <i class="pi pi-sign-out"></i>
        <span>Log out</span>
      </a>
    </div>
  </div>
</div>
