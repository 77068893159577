import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, Validator, Validators} from "@angular/forms";
import {Router} from "@angular/router";
import {AuthService} from "../../auth/auth.service";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  form: FormGroup;
  isLoginDisabled = false;
  errorMessage: string | null = null;

  constructor(
    public fb: FormBuilder,
    public authService: AuthService,
    public router: Router,
  ) {
    this.form = this.fb.group({
      login: ['', Validators.required],
      password: ['', Validators.required],
    });
  }

  ngOnInit() {
    document.documentElement.style.fontSize = '14px';
  }

  login() {
    this.errorMessage = null;
    this.authService.login(
      this.form.controls['login'].value,
      this.form.controls['password'].value,
      (err: string) => this.errorMessage = err
      );
  }

}
