export enum InterestType {
  MUSIC__MOVEMENT ,
  ARTS__CRAFTS,
  READING__LANGUAGES,
  SPORTS,
  IMAGINATIVE_PLAY,
  COOKING,
  PUZZLES__BOARD_GAMES,
  SCIENCE,
  MATH,
  BUILDING,
}
