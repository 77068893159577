import { Component } from '@angular/core';
import { ENVIRONMENT_NAME } from 'src/environments/environment';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html'
})
export class FooterComponent{
  ENVIRONMENT_NAME = ENVIRONMENT_NAME
  constructor() {}
}
