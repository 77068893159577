import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import {AppRoutingModule} from "./app-routing.module";
import {RootComponent} from "./components/root/root.component";
import {LoginComponent} from "./components/login/login.component";
import {HTTP_INTERCEPTORS, HttpClientModule} from "@angular/common/http";
import {AuthInterceptor} from "./auth/auth.interceptor";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {TopBarComponent} from "./components/top-bar/top-bar.component";
import { NavbarComponent } from './components/navbar/navbar.component';
import {MenuComponent} from "./components/menu/menu.component";
import {MenuItemComponent} from "./components/menu/menu-item/menu-item.component";
import {DatePipe} from "@angular/common";
import {AgePipe} from "./pipes/age.pipe";
import {FontAwesomeModule} from "@fortawesome/angular-fontawesome";
import {FooterComponent} from "./components/footer/footer.component";
import {SharedModule} from "./shared/shared.module";
import {ConfirmDialogModule} from "primeng/confirmdialog";

@NgModule({
  declarations: [
    RootComponent,
    LoginComponent,
    TopBarComponent,
    NavbarComponent,
    MenuComponent,
    MenuItemComponent,
    FooterComponent,
  ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        FormsModule,
        BrowserAnimationsModule,
        ReactiveFormsModule,
        HttpClientModule,
        FontAwesomeModule,
        SharedModule,
        ConfirmDialogModule,
    ],
  providers: [
    DatePipe,
    AgePipe,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    }
  ],
  bootstrap: [RootComponent]
})
export class AppModule { }
