<p style="cursor: pointer" class="mb-0" *ngIf="date && localeTimeZone" (click)="bookingAdminDiffString !== '+0' ? op.toggle($event) : null">
  <span> {{userDate | date: 'MM/dd/yyyy hh:mm a '}} {{getTimeZoneByLocaleTimeZone(localeTimeZone)}} </span>
  <span *ngIf="bookingAdminDiffString !== '+0'" style="color: orangered"> ({{bookingAdminDiffString}}) </span>
</p>

<p-overlayPanel #op>
  <ng-template pTemplate>
    <div>
      <span> Booking local time ({{city}}) </span>
      <div *ngIf="bookingAdminDiffString !== '+0'">
        <p-divider></p-divider>
        <div>
          <span>Admin Console user time</span>
        </div>
        <div>
          <span> {{date | date: dateDiffFormat}}</span>
<!--          <span> ({{bookingAdminDiffStringReverse}}hour{{(bookingAdminDiff > 1 || bookingAdminDiff < -1)  ? 's' : ''}}) </span>-->
        </div>
      </div>
    </div>
  </ng-template>
</p-overlayPanel>
